/* You can add global styles to this file, and also import other style files */
/* @import "~flag-icon-css/css/flag-icon.min.css"; */

// Tables styles
// https://swimlane.gitbook.io/ngx-datatable/readme/themes
.ngx-datatable {
    color: var(--secondary) !important; 
    .card {
        background-color: white !important;
    }
}

h1, h2, h3, h4, h5, h6 {
    color: black !important;
}

.datatable-body-cell-label {
    color: black !important; 
}

.stats {
    color: black !important;
}

.page-count {
    color: var(--secondary) !important; 
}

.datatable-row-odd {
    background-color: #fffbe7 !important;
}

.ngx-datatable.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a {
        color: #fff !important;
        background-color: var(--primary) !important;
}

.ngx-datatable.ngx-datatable.bootstrap .datatable-footer .pager li a {
    color:  #fff !important;
    background-color: var(--secondary) !important; 
}

.shadow {
    box-shadow: 0px 0px 18px 0.2px rgba(0,0,0,0.15) !important;
    border-radius: 10px;
}
