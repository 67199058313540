/*
 * DOM element rendering detection
 * https://davidwalsh.name/detect-node-insertion
 */
@keyframes chartjs-render-animation {
  from {
    opacity: 0.99;
  }
  to {
    opacity: 1;
  }
}

.chartjs-render-monitor {
  animation: chartjs-render-animation 0.001s;
}

/*
 * DOM element resizing detection
 * https://github.com/marcj/css-element-queries
 */
.chartjs-size-monitor,
.chartjs-size-monitor-expand,
.chartjs-size-monitor-shrink {
  position: absolute;
  direction: ltr;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;
  z-index: -1;
}

.chartjs-size-monitor-expand > div {
  position: absolute;
  width: 1000000px;
  height: 1000000px;
  left: 0;
  top: 0;
}

.chartjs-size-monitor-shrink > div {
  position: absolute;
  width: 200%;
  height: 200%;
  left: 0;
  top: 0;
}
